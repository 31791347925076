import { render, staticRenderFns } from "./TechProductDemand.vue?vue&type=template&id=613a3107&scoped=true"
import script from "./TechProductDemand.vue?vue&type=script&lang=js"
export * from "./TechProductDemand.vue?vue&type=script&lang=js"
import style0 from "./TechProductDemand.vue?vue&type=style&index=0&id=613a3107&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "613a3107",
  null
  
)

export default component.exports